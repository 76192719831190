import { apiUrl, getInitDataRaw } from "./Variables"
const initDataRaw = getInitDataRaw();

export function getCredit({ user }) {
  
  const sendRequest = async () => {
    const end_point = "/get_balance/"
    const url = apiUrl + end_point
    const formData = {
      user_id: user.chatid,
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      if (data["status"] === "failed") {
        const message = data["message"]
        console.log("error get balance", message)
        return false
      } else {
        return data["balance"]
      }
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}

export function updateCredit({ user, amount }) {
  const sendRequest = async () => {
    const end_point = "/update_balance/"
    const url = apiUrl + end_point
    const formData = {
      user_id: user.chatid,
      amount: amount,
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      if (data["status"] === "failed") {
        const message = data["message"]
        return message
      } else {
        return data["balance"]
      }
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}

export function getUser(){
  const sendRequest = async () => {
    const end_point = "/get_user/"
    const url = apiUrl + end_point
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
      })
      const data = await response.json()
      console.log("Response data:", data)
      const chatid = data['user']['id']
      console.log("chatid:", chatid)
      return chatid
    } catch (error) {
      console.log("error:", error)
      return false
    }
  }
  return sendRequest()
}

export function getPayLink() {
  const sendRequest = async () => {
    const end_point = "/get_payment_link/"
    const url = apiUrl + end_point
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
      })
      const data = await response.json()
      console.log("Response data:", data)
      const payment_link = data["payment_link"]
      console.log("payment_link:", payment_link)
      return payment_link
    } catch (error) {
      console.log("error:", error)
      return false
    }
  }
  return sendRequest()
}

export function sendLog({ log }) {
  const sendRequest = async () => {
    const end_point = "/send_log/"
    const url = apiUrl + end_point
    const formData = {
      log: log,
    }
    console.log("formData:",formData)
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      return true
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}

export function sendWinnersToExecuter(user) {
  const sendRequest = async () => {
    const end_point = "/send_winners/"
    const url = apiUrl + end_point
    const formData = {
      user_id: user.chatid,
      winners: user.winnerItems,
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      if (data["status"] === "failed") {
        const message = data["message"]
        console.log("error get balance", message)
        return false
      } else {
        return true
      }
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}
