import React, { useState } from "react"
import { Wheel } from "react-custom-roulette"

const data = [
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
  { option: "Smart TV" },
  { option: "Car" },
  { option: "Hose" },
  { option: "Computer" },
  { option: "iPhone" },
]

export default () => {
  const [mustSpin, setMustSpin] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(0)

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length)
    setPrizeNumber(newPrizeNumber)
    setMustSpin(true)
  }
  return (
    <>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        outerBorderColor={["#f2f2f2"]}
        outerBorderWidth={[1]}
        innerBorderColor={["#f2f2f2"]}
        radiusLineColor={["#dedede"]}
        radiusLineWidth={[0.5]}
        fontSize={13}
        textColors={["#ffffff"]}
        backgroundColors={[
          "#ff4000",
          "#ff0000",
          "#ff003f",
          "#ff007f",
          "#ff00bf",
          "#ff00ff",
          "#c000ff",
          "#8000ff",
          "#4000ff",
          "#0000ff",
          "#003fff",
          "#003fff",
          "#007fff",
          "#00bfff",
          "#00ffff",
          "#00ffc0",
          "#00ff80",
          "#00ff00",
          "#00ff40",
          "#7fff00",
          "#00ff40",
          "#7fff00",
          "#ffff00",
          "#ffff00",
          "#ffc000",
          "#ffc000",
        ]}
        onStopSpinning={() => {
          setMustSpin(false)
          console.log(data[prizeNumber])
        }}
      />
      <button onClick={handleSpinClick}>SPIN</button>
      {!mustSpin ? data[prizeNumber].option : "0"}
    </>
  )
}
