import React, { useContext, useEffect, useState } from 'react'
import Header from "../Header"
import UserContext from "../UserContext"
import { validateLink } from "./ValidationLink"
import { useNavigate } from 'react-router-dom'


const GetLink = () => {
  const webApp = window.Telegram.WebApp
  const navigate = useNavigate()
  const [link, setLink] = useState("")
  const { user, setUser } = useContext(UserContext)
  const [headerMessage, setHeaderMessage] = useState("")
  useEffect(()=>{
    if (user['lotteryModel'] === 'group') {
      setHeaderMessage("لینک خود را وارد کنید")
    } else {
      setHeaderMessage("لینک خود را وارد کنید")
    }
  })
  const handleChangeLink = (e)=>{
    setLink(e.target.value)
  }
  const onSubmit = async (e) => {
    console.log("on submit")
    e.preventDefault()
    user.channelLink = link
    console.log("user:",user)
    const [isValid, errorMessage] = await validateLink({ user })
    if (!isValid) {
      console.log("errorMessage:", errorMessage)
      webApp.showAlert(errorMessage);
      return
      
    } else {
      navigate("/exceptusers", { replace: true })
    }
  }


 
  return (
    <>
      <Header />
      <main>
        <form className="container getlink__form">
          <div className="getlink__header">
            <label htmlFor="tglink">
              <h2 className="header">{headerMessage}</h2>
            </label>
            <input
              autocomlete="on"
              required
              id="tglink"
              name="tglink"
              type="text"
              placeholder='@hyper_design_bot'
              value={link}
              onChange={(e) => {
                handleChangeLink(e)
              }}
            />
          </div>
          <button 
          className="button btnshadow getlink__button" 
          onClick={onSubmit}>
            تایید
          </button>
        </form>
      </main>
    </>
  )
}

export default GetLink
