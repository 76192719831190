import React, { useState } from "react";

// // Create a Context
const UserContext = React.createContext()

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    channelLink: null,
    countWinner: 3,
    countMembers: 0,
    lotteryModel: null,
    listMembers: [],
    exceptUsers: [],
    title: null,
    theme: "default",
    chatid: null,
    step: 1,
    page:'confirm',
    play:false,
    winnerItems:[],
  })

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext;
