import "./index.css"
import "@telegram-apps/telegram-ui/dist/styles.css"

import { AppRoot} from "@telegram-apps/telegram-ui"
import { TooltipSection } from "./Components/Telegram/TooltipSection/TooltipSection"
import MainPage from "./Components/Telegram/Forms/MainPage"
import GetLink from "./Components/Telegram/Forms/GetLink"
import CountWinner from "./Components/Telegram/Forms/CountWinner"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import ShowUsers from "./Components/Telegram/RunLottery/ShowUsers"
import ShowExcepts from "./Components/Telegram/ExceptUsers/ShowExcepts"
import DemoRun from "./Components/Telegram/Themes/DemoRun"
import React from "react"
import StartLottery from "./Components/Telegram/RunLottery/StartLottery"
import CountDown from "./Components/Telegram/RunLottery/CountDown"
import { UserProvider } from "./Components/Telegram/UserContext"
import DownloadVideo from "./Components/Telegram/RunLottery/DownloadVideo"
import SelectTheme from "./Components/Telegram/Themes/SelectTheme"
import DisplayWinners from "./Components/Telegram/RunLottery/DisplayWinners"
import Roulette from "./Components/Telegram/LuckyWheel/roulette"
function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/getlink" element={<GetLink />} />
          <Route path="/countwinners" element={<CountWinner />} />
          <Route path="/showusers" element={<ShowUsers />} />
          <Route path="/exceptusers" element={<ShowExcepts />} />
          <Route path="/demo" element={<DemoRun />} />
          <Route path="/confirm" element={<StartLottery />} />
          <Route path="/countdown" element={<CountDown />} />
          <Route path="/video" element={<DownloadVideo />} />
          <Route path="/select" element={<SelectTheme />} />
          <Route path="/winners" element={<DisplayWinners />} />
          <Route path="/spin" element={<Roulette />} />
          <Route path="/tooltip" element={<TooltipSection />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  )
}

export default App
