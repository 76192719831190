export function get_winners_item(mixedNameIdsUsername, winnersListUserIds) {
  const winnerItems = mixedNameIdsUsername.filter((item) => {
    if (winnersListUserIds.includes(item.user_id)) {
      console.log("item :", item)
      return item
    }
  })
  console.log("winnerItems:", winnerItems)
  const winnerNames = winnerItems.map((item) => {
    if (item.username) {
      return `@${item.username}`
    } else {
      return item.fullname
    }
  })
  return [winnerItems, winnerNames]
}
