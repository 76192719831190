import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import UserContext from "../UserContext"
import Header from "../Header"
import { apiUrl, getInitDataRaw } from "../../../utils/Variables"
import { sendLog, getUser } from "../../../utils/TelLotteryApi"



const MainPage = () => {
  const { user, setUser } = useContext(UserContext)
  const initDataRaw = getInitDataRaw();
  const [auth, setAuth] = useState(false)
  console.log("init data: ", initDataRaw)
  
  
  const navigate = useNavigate();
  
  
  useEffect(() => {
    async function fetchChatId() {
      const chat_id = await getUser()
      console.log("chatid is:", chat_id)
      if (chat_id) {
        setAuth(true);
      }
      setUser((prevUserInfo) => ({
        ...prevUserInfo,
        chatid: chat_id,
      }))
      const log = `chatis ${chat_id} start the webapp`
      await sendLog({log})
    }

    fetchChatId()
    
    console.log("user:", user)
    
    if (
      typeof window !== "undefined" &&
      window.Telegram &&
      window.Telegram.WebApp
    )
      // webApp.MainButton.setText("برگشت به ربات").show()
      console.log("ok");
  }, [])
  
  const setModelGroup = (event, lotteryModel) => {
    console.log("lotteryModel:", lotteryModel)
    event.preventDefault()
    navigate("/getlink", { replace: true })
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      lotteryModel: lotteryModel,
      step: 2,
    }))
  }
  const goToSpin = (event, lotteryModel) => {
    event.preventDefault()
    navigate("/spin", { replace: true })
  }


  return (
    <>
      <main class="container model">
        {auth ? (
          <>
            <h2 class="model__header">
              <span class="nowrap">قصد برگزاری چه نوع قرعه کشی را دارید؟ </span>
            </h2>
            <div className="model__buttons">
              <button
                class="button btn--selectmodel"
                onClick={(e) => setModelGroup(e, "all-group")}
              >
                بین اعضای گروه
              </button>
              <button
                class="button btn--selectmodel"
                onClick={(e) => setModelGroup(e, "all-channel")}
              >
                بین اعضای کانال
              </button>
              {/* <button
                class="button btn--selectmodel"
                onClick={(e) => goToSpin(e, "all-channel")}
              >
                گردونه ی شانس
              </button> */}
            </div>
          </>
        ) : (
          <p style={{ color: "white" }}> Loading ...</p>
        )}
      </main>
    </>
  )
}

export default MainPage
