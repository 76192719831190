import React, { useContext, useEffect, useState } from 'react'
import UserContext from "../UserContext"
import { getPayLink } from '../../../utils/TelLotteryApi'

const AlertCredit = ({ price, setShowCreditPopup }) => {
  const { user, setUser } = useContext(UserContext)
  const [paymentLink, setPaymentLink] = useState('')
  const handleGotIt = () => {
    setShowCreditPopup(false)
  }
  useEffect(()=>{
    async function fetchPayLink() {
      const payLink = await getPayLink()
      console.log("payLink is:", payLink)
      if (payLink) {
        setPaymentLink(payLink)
      }      
    }
    fetchPayLink()
  },[])

  return (
    <section class="alert-record">
      <p>
        اعتبار شما کافی نیست برای اجرای قرعه کشی باید {price.toLocaleString()}{" "}
        تومان اعتبار داشته باشید
      </p>
      <button onClick={handleGotIt} class="alert-record__button nowrap">
        متوجه شدم
      </button>

      <form>
        <button 
        formAction={paymentLink}
        class="alert-record__button nowrap secondary">شارژ حساب</button>
      </form>
    </section>
  )
}

export default AlertCredit
