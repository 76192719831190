import React, { useContext, useEffect, useRef, useState } from "react"
import ConfirmLottery from "./ConfirmLottery"
import UserContext from "../UserContext"
import CountDown from "../RunLottery/CountDown"
import v1 from '../../../statics/audio/v1.mp3';  // Adjust the path relative to your component
import ShowUsers from "./ShowUsers"

const StartLottery = () => {
  const { user } = useContext(UserContext)
  const [currentPage, setCurrentPage] = useState(user.page)
  const [confirmPage, setConfirmPage] = useState(currentPage === "confirm")
  const [countDownPage, setCountDownPage] = useState(
    currentPage === "countdown"
  )
  const [runLottoPage, setRunLottoPage] = useState(currentPage === "realrun")
  const [playMusic1, setPlayMusic1] = useState(false)
  
  // const music1Ref = useRef(null)
  console.log("enter start lottery")
  useEffect(() => {
    // Set up an interval to check user.page every second
    
    const interval = setInterval(() => {
      if (user.play){
        setPlayMusic1(true);
        
      }
      if (user.page !== currentPage) {
        setCurrentPage(user.page)

        // Update the states to show the appropriate page
        if (user.page === "confirm") {
          setConfirmPage(true)
          setCountDownPage(false)
        } else if (user.page === "countdown") {
          setConfirmPage(false)
          setCountDownPage(true)
        } else if (user.page === "realrun") {
          setCountDownPage(false)
          setRunLottoPage(true)
        }
      }
    }, 1000) // Check every second (1000 ms)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval)
  }, [user, currentPage])

  return (
    <>
      {/* Audio elements for the music tracks */}
      {confirmPage && <ConfirmLottery/>}
      {countDownPage && <CountDown />}
      {runLottoPage && <ShowUsers />}
    </>
  )
}

export default StartLottery
