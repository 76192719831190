function normalizingExceptUsers(exceptUsers) {
  const stringExceptUsers = exceptUsers.reduce((acc, curr) => {
    const lowerStringCurr = String(curr).toLowerCase()
    if (lowerStringCurr.includes("@")) {
      console.log("lowerStringCurr has @", lowerStringCurr)
      const removedAtSign = lowerStringCurr.replace("@", "")
      acc.push(removedAtSign)
    } else {
      acc.push(lowerStringCurr)
    }
    console.log("acc:", acc)
    return acc
  }, [])
  console.log("filteredItems is ", stringExceptUsers)
  return stringExceptUsers
}


// console.log("normalizedExceptUsers:", normalizedExceptUsers)

// const mixedNameIdsUsername = [
//   { user_id: 223356203, fullname: "Jgfygvnh", username: null },
//   { user_id: 6086645413, fullname: "Dutcitdit", username: 'deveelooper' },
//   { user_id: 6126352633, fullname: "Madeleine Jackson", username: null },
//   { user_id: 6134265852, fullname: "Vahid Kudt", username: null },
//   { user_id: 6169075897, fullname: "Ldbdjd", username: null },
//   { user_id: 6206699810, fullname: "B-Z", username: "null" },
//   { user_id: 6141001398, fullname: "جواد", username: "Javad494" },
// ]

export function extractAllowesUsers(mixedNameIdsUsername, exceptUsers) {
  const normalizedExceptUsers = normalizingExceptUsers(exceptUsers)
  const filteredIds = mixedNameIdsUsername.reduce((acc, curr) => {
    if (!normalizedExceptUsers.includes(String(curr.user_id).toLowerCase())) {
      console.log(`${curr} in ${normalizedExceptUsers}`)
      acc.push(curr)
    }
    return acc
  }, [])

  const filteredFullnames = filteredIds.reduce((acc, curr) => {
    if (!normalizedExceptUsers.includes(String(curr.fullname).toLowerCase())) {
      console.log(`${curr} in ${normalizedExceptUsers}`)
      acc.push(curr)
    }
    return acc
  }, [])
  const filteredUserNames = filteredFullnames.reduce((acc, curr) => {
    if (!normalizedExceptUsers.includes(String(curr.username).toLowerCase())) {
      console.log(`${curr} in ${normalizedExceptUsers}`)
      acc.push(curr)
    }
    return acc
  }, [])
  const allowUsers = filteredUserNames.map(myFunction)
  function myFunction(item) {
    if (item.username) {
      return `@${item.username}`
    } else {
      return item.fullname
    }
  }
  const userIds = filteredUserNames.map((item) => item.user_id)
  
  return [allowUsers, userIds]
}


