import React, { useContext, useEffect, useState, useRef } from "react";
import UserContext from "../UserContext";
import _, { flatMap, set } from "lodash";
import Header from "../Header";
import DisplayUsers from "./DisplayUsers";
import DisplayWinners from "./DisplayWinners";
import v2 from '../../../statics/audio/v2.mp3';  // Adjust the path relative to your component
import v1 from '../../../statics/audio/v1.mp3'; 
import {availableThemes, demo_names} from '../../../utils/Helper'
import { extractAllowesUsers } from "../../../utils/extract_allowed_users";
import { get_winners_item } from "../../../utils/get_winners"


const ShowUsers = ({ demo}) => {
  const initIndex = 10
  const regularSleep = 100
  const highlightSleep = 1000
  const { user, setUser } = useContext(UserContext)
  const [listNames, setListNames] = useState([])
  const [names, setNames] = useState([])
  
  const [offset, setOffSet] = useState(1)
  const [winners, setWinners] = useState([])
  const [showWinners, setShowWinners] = useState(false)
  const [neigberIndices, setIneigberIndices] = useState([])
  const [sleepTime, setSleepTime] = useState(regularSleep)
  const [startLotto, setStartLotto] = useState(false)
  const [theme, setTheme] = useState(user.theme)
  const [themeClass, setThemeClass] = useState(
    availableThemes.includes(theme) ? theme : "default"
  )
  const music2Ref = useRef(null)
  const music1Ref = useRef(null)
  
  useEffect(() => {
    document.body.style.backgroundColor = "#FF4D49"
    document.body.style.backgroundImage = null;
    
  }, [])

  useEffect(()=>{
    if (!startLotto) {
      return
    }
    if (sleepTime === regularSleep){
      if (music1Ref.current.paused) {
        music1Ref.current.play()
      }
      
      if (!music2Ref.current.paused) {
        music2Ref.current.pause()
        music2Ref.current.currentTime = 0
      }
    }
    if (sleepTime === highlightSleep){
      if (!music1Ref.current.paused){
        music1Ref.current.pause()
      }
      if (music2Ref.current.paused){
        music2Ref.current.play()
      }
    }
  },[sleepTime,startLotto]);

  useEffect(() => {
    if (!startLotto) {
      return
    }
    if (neigberIndices.includes(offset)) {
      setSleepTime(highlightSleep)
    } else {
      setSleepTime(regularSleep)
    }
  }, [offset])

  // Select winners before starting the rotation
  useEffect(() => {
    const allNames = demo ? demo_names : user.listMembers
    const exceptUsers = user.exceptUsers.map((item) => item.name)
    const result = extractAllowesUsers(allNames, exceptUsers)
    const names = result[0]
    const userIds = result[1]
    setNames(names)
    const firstSlice = names.slice(0, initIndex)
    setListNames(firstSlice)
    const listWinnersIds = _.sampleSize(userIds, user.countWinner)
    const winners_list = get_winners_item(allNames, listWinnersIds)
    const winnerItems = winners_list[0]
    const listWinners = winners_list[1]
    
    user.winnerItems = winnerItems
    setWinners(listWinners)
    setTheme(user.theme)
    setThemeClass(availableThemes.includes(theme) ? theme : "default")
  }, [])
  useEffect(() => {
    if (!startLotto) {
      return
    }
    setOffSet((prevOffset) => prevOffset + 1)
    setTimeout(() => {
      if (showWinners) {
        return
      }
      const listSlice = names.slice(offset, offset + initIndex)
      setListNames(listSlice)
      if (listNames.length === 0) {
        setShowWinners(true)
      }
      const indices = winners
        .map((winner) => names.indexOf(winner))
        .filter((index) => index > 1)
      const neigberIndices = indices.flatMap((number) =>
        [number - 6, number - 5, number - 4, number - 3, number - 2].filter(
          (index) => index > 1
        )
      )
      setIneigberIndices(neigberIndices)
    }, sleepTime)
    return () => clearTimeout()
  }, [listNames, startLotto])

  
  const handleClick = () => {
    setStartLotto(true)
    music1Ref.current.play();
    music2Ref.current.play();
    music2Ref.current.pause();
  }
  function getStyleStart(){

    if (user.theme === "gold") {
      return "gold-start"
    } else {
      return "def-start"
    }
  }
  const styleStart = getStyleStart()
  return (
    <>
      <Header />
      <main class={`container ${themeClass} theme`}>
        <section class={`users ${themeClass}--users`}>
          {showWinners ? (
            <DisplayWinners winners={winners} demo={demo}></DisplayWinners>
          ) : (
            <DisplayUsers winners={winners} listNames={listNames} theme={theme} />
          )}
        </section>
        {!startLotto && (
          <div
            className={`start-lottery-button ${styleStart}`}
            onClick={handleClick}
          >
            شروع
          </div>
        )}
      </main>
      <audio ref={music1Ref} src={v1}></audio>
      <audio ref={music2Ref} src={v2}></audio>
    </>
  )
}

export default ShowUsers
