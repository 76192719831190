import { useEffect, useRef, useState } from "react"


const DisplayUsers = ({ listNames, winners,theme,demo }) => {
  const [scrollable, setScrollable] = useState(false)
  const myRef = useRef(null)

  useEffect(() => {
    const offsetHeight = myRef.current?.offsetHeight
    const scrollHeight = myRef.current?.scrollHeight
    if (offsetHeight === scrollHeight) {
      setScrollable(true)
    }
  }, [listNames])

  
  function getStyleGighLight() {
    if (theme === "gold") {
      return "higlight--gold"
    } else {
      return "higlight--def"
    }
  }
  const styleHigLight = getStyleGighLight()
  return (
    <>
    
      {demo && (
        <>
          <p style={{ fontSize: "1rem", color:"orange"}}>
            این اسامی فرضی هستند ( پیش نمایش)
          </p>
        </>
      )}
      <ul>
        {listNames.map((name, index) => {
          if (index === 3 && winners.includes(name)) {
            return (
              <li key={index} style={{ direction: "ltr" }}>
                <h1 className={`winner-highlight ${styleHigLight}`}>{name}</h1>
              </li>
            )
          }
          return (
            <li key={index} style={{ direction: "ltr" }}>
              {name}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default DisplayUsers