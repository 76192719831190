import React, { useContext, useState } from 'react'
import Header from "../Header"
import UserContext from "../UserContext"
import { useNavigate } from 'react-router-dom'

const CountWinner = () => {
  const webApp = window.Telegram.WebApp
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext)
  const [winnersCount, setWinnerCount] = useState(1)
  const handleChange = (e) => {
    console.log("e.target.value", e.target.value)
    setWinnerCount(e.target.value)
    
    console.log("user:", user)
    // navigate("/themes", { replace: true })
  }

  const handleClick = ()=>{
    if (winnersCount>10){
      webApp.showAlert("تعداد بیش از 10 کاربر مجاز نیست ❗️")
      return
    }
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      countWinner: winnersCount,
      step: 5,
    }))
    navigate("/select", { replace: true })
  }
  const defaultTemplate = () => {
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      theme: 'default',
      step: 6,
    }))
    navigate("/confirm", { replace: true })
  }
  return (
    <>
      <Header />
      <main>
        <div class="whitebox winnerscount">
          <div class="header winnerscount__head">
            <label for="winners" class="winners__label">
              تعداد برندگان
            </label>
            <div className="winnercount--input">
              <h4 >کاربر</h4>
              <input
                type="number"
                id="tentacles"
                name="tentacles"
                min="1"
                max="10"
                onChange={handleChange}
                value={winnersCount}
                className='inputwinner'
              />
            </div>
          </div>

          <button
            onClick={handleClick}
            class="btn btn-primary btn--seltemplate"
          >
            انتخاب قالب
          </button>
          <button
            onClick={defaultTemplate}
            class="btn btn-secondary btn--deftemplate"
          >
            قالب پیش فرض
          </button>
        </div>
      </main>
    </>
  )
}

export default CountWinner
