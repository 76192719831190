

export function getModelPersian({ user }) {
  if (user["lotteryModel"] === "group") {
    return "بین اعضای گروه"
  }
  return "بین اعضای کانال"
}
export function getThemePersian({ user }) {
  if (user.theme === "default") {
    return "پیش فرض"
  }
  if (user.theme === "gold") {
    return "طلایی"
  }
  if (user.theme === "purple") {
    return "ارغوانی"
  }
  return "پیش فرض"
}

export function getPrice(lotteryModel) {
  if (lotteryModel === "all-group") {
    return 20000
  }
  if (lotteryModel === "all-channel") {
    return 20000
  }
  return false
}

export const availableThemes = ["purple", "gold", "default"]

export const demo_names = [
  { user_id: 1000000001, fullname: "John Smith", username: null },
  { user_id: 1000000002, fullname: "علی رضایی", username: null },
  { user_id: 1000000003, fullname: "Emily Johnson", username: "emilyj" },
  { user_id: 1000000004, fullname: "مهدی کاظمی", username: "mehdi_kaz" },
  { user_id: 1000000005, fullname: "Michael Brown", username: "mikebrown" },
  { user_id: 1000000006, fullname: "فاطمه احمدی", username: null },
  { user_id: 1000000007, fullname: "James Williams", username: "jwilliams" },
  { user_id: 1000000008, fullname: "زهرا حسینی", username: "zahrah_hsn" },
  { user_id: 1000000009, fullname: "Sophia Martinez", username: null },
  { user_id: 1000000010, fullname: "حسین عباسی", username: "hosein_abb" },
  { user_id: 1000000011, fullname: "Alexander Lee", username: null },
  { user_id: 1000000012, fullname: "ریحانه موسوی", username: "reyhanemos" },
  {
    user_id: 1000000013,
    fullname: "Christopher Walker",
    username: "chris_walker",
  },
  { user_id: 1000000014, fullname: "علی‌اصغر نادری", username: "ali_nad" },
  { user_id: 1000000015, fullname: "Sarah Taylor", username: "saraht123" },
  { user_id: 1000000016, fullname: "سینا مرادی", username: null },
  { user_id: 1000000017, fullname: "Daniel Harris", username: "danharris" },
  { user_id: 1000000018, fullname: "محسن سلیمانی", username: null },
  { user_id: 1000000019, fullname: "Matthew Clark", username: "mattclark" },
  { user_id: 1000000020, fullname: "نگار قاسمی", username: "negar_gh" },
  { user_id: 1000000021, fullname: "Andrew Lewis", username: null },
  { user_id: 1000000022, fullname: "رضا ملکی", username: "reza_malaki" },
  {
    user_id: 1000000023,
    fullname: "Isabella Robinson",
    username: "isabella_r",
  },
  { user_id: 1000000024, fullname: "زهیر امینی", username: "zoheir_amin" },
  { user_id: 1000000025, fullname: "Elijah Scott", username: "elijahscott" },
  { user_id: 1000000026, fullname: "شادی کریمی", username: "shadi_km" },
  { user_id: 1000000027, fullname: "Benjamin Young", username: "benyoung" },
  { user_id: 1000000028, fullname: "نوید تهرانی", username: "navid_tei" },
  { user_id: 1000000029, fullname: "Olivia King", username: null },
  { user_id: 1000000030, fullname: "علی اکبر معصومی", username: null },
  { user_id: 1000000031, fullname: "Liam Hall", username: "liamhall" },
  { user_id: 1000000032, fullname: "سحر محمدی", username: "sahar_mhd" },
  { user_id: 1000000033, fullname: "Noah Allen", username: "noah_allen" },
  { user_id: 1000000034, fullname: "بهزاد کیانی", username: null },
  { user_id: 1000000035, fullname: "William Nelson", username: "will_nelson" },
  { user_id: 1000000036, fullname: "پریسا نجفی", username: "parisa_nj" },
  { user_id: 1000000037, fullname: "Lucas Carter", username: "lucas_carter" },
  { user_id: 1000000038, fullname: "عباس طالبی", username: null },
  { user_id: 1000000039, fullname: "Jacob Baker", username: "jacobbaker" },
  { user_id: 1000000040, fullname: "مریم سهرابی", username: null },
  { user_id: 1000000041, fullname: "Henry Perez", username: "henryperez" },
  { user_id: 1000000042, fullname: "مصطفی جعفری", username: null },
  { user_id: 1000000043, fullname: "Mason Thompson", username: "mason_t" },
  { user_id: 1000000044, fullname: "نگین فرهمند", username: "negin_fh" },
  { user_id: 1000000045, fullname: "Logan White", username: "logan_white" },
  { user_id: 1000000046, fullname: "کامران فتحی", username: "kamran_fathi" },
  { user_id: 1000000047, fullname: "Ethan Adams", username: "ethan_adams" },
  { user_id: 1000000048, fullname: "مینا حیدری", username: null },
  { user_id: 1000000049, fullname: "Jayden Hill", username: "jaydenhill" },
  { user_id: 1000000050, fullname: "صبا رضوانی", username: "saba_rzv" },
  {
    user_id: 1000000051,
    fullname: "Sebastian Ramirez",
    username: "sebramirez",
  },
  { user_id: 1000000052, fullname: "امیرعلی پناهی", username: null },
  { user_id: 1000000053, fullname: "Aiden Wright", username: "aiden_w" },
  { user_id: 1000000054, fullname: "علیرضا صفری", username: "alireza_sf" },
  { user_id: 1000000055, fullname: "David Harris", username: null },
  { user_id: 1000000056, fullname: "پرنیان شایسته", username: null },
  { user_id: 1000000057, fullname: "Oliver Martin", username: null },
  { user_id: 1000000058, fullname: "محسن زارعی", username: null },
  { user_id: 1000000059, fullname: "Charlotte Lewis", username: "charlot_lws" },
  { user_id: 1000000060, fullname: "آرمان یگانه", username: "arman_ygn" },
  {
    user_id: 1000000061,
    fullname: "Samuel Anderson",
    username: "samuelanderson",
  },
  { user_id: 1000000062, fullname: "مهتاب احمدیان", username: null },
  { user_id: 1000000063, fullname: "Anthony Collins", username: "anthony_col" },
  { user_id: 1000000064, fullname: "هادی نظری", username: null },
  { user_id: 1000000065, fullname: "Joshua Bell", username: "joshuabell" },
  { user_id: 1000000066, fullname: "زینب صادقی", username: null },
  { user_id: 1000000067, fullname: "Gabriel Lee", username: "gabrielee" },
  { user_id: 1000000068, fullname: "علیرضا رفیعی", username: "alireza_rfi" },
  { user_id: 1000000069, fullname: "Grace Wilson", username: "gracewilson" },
  { user_id: 1000000070, fullname: "شایان کریمی", username: "shayan_krm" },
  { user_id: 1000000071, fullname: "Isaac Sanchez", username: null },
  { user_id: 1000000072, fullname: "سپیده اسدی", username: "sepideh_asd" },
  { user_id: 1000000073, fullname: "Dylan Rogers", username: "dylanrogers" },
  { user_id: 1000000074, fullname: "مجتبی نوری", username: null },
  { user_id: 1000000075, fullname: "Jack Russell", username: "jackrussell" },
  { user_id: 1000000076, fullname: "پیمان صفوی", username: "peyman_sf" },
  { user_id: 1000000077, fullname: "Arezo", username: null },
  { user_id: 1000000077, fullname: "samira", username: null },
  { user_id: 1000000077, fullname: "amin", username: null },
  { user_id: 1000000077, fullname: "نازنین", username: null },
  { user_id: 1000000077, fullname: "اکرم", username: null },
  { user_id: 1000000077, fullname: "سمیه", username: null },
  { user_id: 1000000077, fullname: "گوهر", username: null },
  { user_id: 1000000077, fullname: "زیبا", username: null },
  { user_id: 1000000077, fullname: "مستانه", username: null },
  { user_id: 1000000077, fullname: "آرام جعفری", username: null },
  { user_id: 1000000077, fullname: "هما شریفی نیا", username: null },
  { user_id: 1000000077, fullname: "محمدرضا", username: null },
  { user_id: 1000000077, fullname: "سیما بینا", username: null },
  { user_id: 1000000077, fullname: "محمد سبزواری", username: null },
  { user_id: 1000000077, fullname: "nima", username: null },
  { user_id: 1000000077, fullname: "Ahmadi", username: null },
  { user_id: 1000000077, fullname: "Sherkat Datin", username: null },
  { user_id: 1000000077, fullname: "Soheila Ahmadi", username: null },
  { user_id: 1000000077, fullname: "Somayeh", username: null },
  { user_id: 1000000077, fullname: "Sharife", username: null },
  { user_id: 1000000077, fullname: "Mina", username: null },
]
