import {useContext, useEffect, useState} from "react"
import useWindowSize from "react-use/lib/useWindowSize"
import Confetti from "react-confetti"
import UserContext from "../UserContext"
import { updateCredit } from "../../../utils/TelLotteryApi"
import { sendWinnersToExecuter } from "../../../utils/TelLotteryApi"



function DisplayWinners ({winners, demo}) {
  const { width, height } = useWindowSize();
  const { user, setUser } = useContext(UserContext);
  const [updated, setUpdated] = useState(false);
  const [isDemo, setIsDemo] = useState(demo)
  const theme = user.theme;

  const updateBalance = async (e) => {
    const amount = 20000;
    const newCredit = await updateCredit({ user, amount });
  };

  useEffect(()=>{
    console.log("isDemo", isDemo)
    if (updated) {
      return
    }
    if (!isDemo) {
      updateBalance()
    }
    
    setUpdated(true);
    const sent = sendWinnersToExecuter(user)
  },[]);

  return (
    <>
      <Confetti width={width} height={height} />
      <div className="winners--section">
        <div className={`${theme}--users winners-head`}>
          {winners.length === 1 ? "برنده" : "برندگان"}
        </div>
        <hr/>
        <div className="winners--names">
          {winners.map((winner, index) => (
            <div className={`${theme}--users name`} key={index}>
              {winner}
            </div>
          ))}
        </div>
      </div>
    </>
  )
  };
export default DisplayWinners
